import React from "react"
import styled from 'styled-components'

import scottImage from "../../assets/images/scott.jpg";

const style = {
  rightCopy: styled.div`
    font-size: 14px;
    font-family: Arial;
  `, 
  leftCopy: styled.div`
    padding-top: 20%;
  `
}

const AboutAuthorSection = () => (
  <div className="o-section">
    <style.rightCopy>
      <h2>About the Author</h2>
      <p>Global Real Estate Crowdfunder &amp; Africa’s Leading Global Investor.</p>
      <p>Scott is a serial entrepreneur, author of the book, Property Going Global, which was written in association with Clem Sunter and is the authority on FinTech and the intersection of International Real Estate Investment. He is a master real estate analyst and investment real estate acquisitions expert and active in several global markets including USA, UK, Australia, Asia and South Africa. Scott is passionate about crowdfunding and how it is going to revolutionize real estate forever and create global wealth for all. In 2015 he was invited as a member to International Crowdfinance Leadership Council (ICLC). He is also one of the founding members of the African Crowdfunding Association (AfCA), Africa’s only crowdfunding association which is moving Africa forward.</p> 
      <p>Scott is the Founder and CEO of Wealth Migrate, Your trusted Global Real Estate Marketplace. What makes us unique is we have 227+ years of combined international real estate experience, have facilitated over 10780+ investments internationally on 5 continents and to a value of over $1,34+ billion. We have established relationships with Best-of Breed partners globally, to find the elite off-market deals and execute on the opportunities. Our philosophy is safety, transparency and only partnering where all parties interests are aligned.</p>
      <p>Although he started from humble beginnings he did his first property project at 13, his first development at 19 and his first international investment at 22. In 1998 he wrote his dissertation about how IT was going to revolutionize the property industry and today what he wrote about is affectionately known as crowdfunding. He is now invited globally to present at the best real estate, FinTech and crowdfunding events to explain what is happening in this space globally!</p>
      <p>Scott’s purpose is to drive the Wealth Movement and empower a billion people by 2020!</p>
      <div className="buttons">
        <a href="http://scottpicken.com/" target="_blank" rel="noopener noreferrer" className="o-button o-button--solid">More about Scott</a>
        <a href="https://www.youtube.com/user/spicken09" target="_blank" rel="noopener noreferrer" className="o-button o-button--lined__option-1">View videos from Scott</a>
      </div>
    </style.rightCopy>

    <style.leftCopy>
      <img src={ scottImage } alt="Scott Picken" />
    </style.leftCopy>
    
  </div> 
  
)

export default AboutAuthorSection
