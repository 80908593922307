import React from "react"
import styled from 'styled-components'

const style = {
  about: styled.div`
    background-color: #f7f7f7;
  `,
  leftCopy: styled.div`
    font-size: 14px;
    font-family: Arial;
  `,
  list: styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
    list-style: none;
    font-family: Arial;
    
    li {
      display: flex;
    }
    span {
      flex: none;
      display: flex;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      background-color: orange;
      border: none;
      color: white;
      font-size: 16px;
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
  `
}

const AboutSection = () => (
  <style.about>
    <div className="o-section">
      <style.leftCopy>
        <h2>About the book</h2>
        <p>If you’re looking to broaden your knowledge on how to invest in international real estate, find solutions to securing your financial future and learn how to create global wealth security with a steadfast system, Property Going Global by Scott Picken is the book for you.</p>
        <p>In association with Clem Sunter, Scott Picken advises readers on how to invest internationally and create global wealth in a way that is safe and secure using a tried and tested system. Property Going Global is recognised as the authority on investing internationally.</p>
        <p>Property Going Global includes up-to-date advice from Clem Sunter, who has previously developed a method of projecting various scenarios for both the future of South Africa and the world. Clem Sunter’s advice will help readers decide on the best possible solution for various types of potential events, including the future of the Rand.</p>
        <p>The book also analyses 16 international property markets, including the USA, Australia, the UK, South Africa, Cyprus, and Mauritius, as well as many more, and investigates the latest property trends, residency and how to get foreign passports, mistakes made, and lessons learned. It offers many tools to be successful and has previously helped over 2000 people invest internationally.</p>
        <p>Currently, 4 out of 5 adults in South Africa are concerned about their future and are looking for answers, solutions and systems to secure their future. Property Going Global is essential for all investors and has been personally endorsed by 40 of the most well-recognized people both in South Africa and internationally. Property Going Global provides definitive answers to every reader’s financial future.</p>
      </style.leftCopy>

      <div className="about__right">
        <h2>Valuable insights you will learn</h2>
        <style.list>
          <ol>
            <li><span>1</span>How to invest in Real Estate internationally</li>
            <li><span>2</span>How to create Global Wealth Safety with a tried &amp; tested system</li>
            <li><span>3</span>Up to date advice from Clem Sunter</li>
            <li><span>4</span>Answers and solutions to your financial future</li>
            <li><span>5</span>Review of the top 16 markets including the USA, UK and Australia</li>
            <li><span>6</span>A chapter on where to invest to get a passport</li>
          </ol>
        </style.list>  
      </div>
    </div>
  </style.about>
)

export default AboutSection
