import React from "react"
import $ from 'jquery';

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeaderSection from "../components/Header/index.js";
import AboutBookSection from "../components/AboutBook/index";
import GetCopySection from "../components/GetCopy/index";
import AboutAuthorSection from "../components/AboutAuthor/index";
import FooterSection from "../components/Footer/index";

if (typeof window !== 'undefined') {
  $(window).scroll(function() {
    const height = $(window).scrollTop();
    if (height > 600) {
      $('.backToTop').fadeIn();
    } else {
      $('.backToTop').fadeOut();
    }
  });
  
  $(document).ready(function() {
    $(".backToTop").click(function(event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
    });
  });
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <HeaderSection />

    <AboutBookSection />

    <GetCopySection />

    <AboutAuthorSection />

    <FooterSection />

    <a className="backToTop" href="#">&#10148;</a>

  </Layout>
)

export default IndexPage
