import React from "react"
import styled from 'styled-components'

import smallBook from "../../assets/images/book_small.png";

import "../../assets/css/stylesheet.css";
import "../../components/css/headerSection.css";

const style = {
  heading: styled.div`
    text-transform: uppercase;
    color: #f99717;
    font-size: 2em;
    font-weight: 500;
  `,
  description: styled.div`
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
    font-family: Arial;
  `,
  image: styled.div`
    padding-top: 20px;
  `,
  leftCopy: styled.div`
    padding-top: 50%;
  `,
  leftContent: styled.div`
    color: #ffffffff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: 26px;
    font-weight: 900;
    line-height: 40px;
    padding-top: 50%;
    font-family: Arial;
  `
}

const HeaderSection = () => (
  <div className="o-section header">
    <rightCopy>
      <style.heading>
        <h1>Property Going <strong>Global</strong></h1>
      </style.heading>
      <style.description>
        <p>How to create global wealth and invest with confidence.</p>
      </style.description>
      <style.image>
        <img src={ smallBook } alt="Property Going Global" />
      </style.image>  
    </rightCopy>

    <leftCopy>
      <style.leftContent>
        <p>Learn from our successful experience of helping over 2400+ people invest internationally to a value of over $600 million!</p>
      </style.leftContent>
      <div className="buttons">
        <a href="https://www.payfast.co.za/eng/process/payment?p=c7ogou83g31jr51eg49ju64f50" target="_blank" rel="noopener noreferrer" className="o-button o-button--solid">Buy now</a>
        { /* <a href="http://globalwealth.force.com/surveypropgoingglobal" target="_blank" rel="noopener noreferrer" className="o-button o-button--lined">Get your free copy</a> */ }
      </div>
    </leftCopy> 
    
  </div>
  
)

export default HeaderSection
