import React from "react"
import styled from 'styled-components'

import smallBook from "../../assets/images/book_small.png";

const style = {
  getCopy: styled.div`
    background-color: #343538;
  `,
  leftCopy: styled.div`
  h3 {
    color: #ffffff;
    font-size: 40px;
  }
  h4 {
    color: #f99117;
    text-transform: uppercase;
    font-size: 40px;
  }
  p {
    color: #ffffff;
    font-family: Arial;
  }
`
}

const GetCopySection = () => (
  <style.getCopy>
    <div className="o-section">
      <img src={ smallBook } alt="Property Going Global" />
      <style.leftCopy>
        <h3>Get your copy of this book for free, in digital PDF format.</h3>
        <h4>Right now</h4>
        <p>Fill in the survey and the download link for the book will be emailed to you immediately on completion</p>
        <div className="buttons">
          <a href="https://www.payfast.co.za/eng/process/payment?p=0estgvndds7oo8ck1k0cp610g1" target="_blank" rel="noopener noreferrer" className="o-button o-button--solid">Buy now</a>
          { /*<a href="http://globalwealth.force.com/surveypropgoingglobal" target="_blank" rel="noopener noreferrer" className="o-button o-button--lined__option">Get your free copy</a> */ }
        </div>
      </style.leftCopy>
    </div>
  </style.getCopy>

)

export default GetCopySection
