import React from "react"
import styled from 'styled-components'

import ipsLogo from "../../assets/images/IPS-Logo_New.png";
import wmLogo from "../../assets/images/wm_logo.png";

const style = {
  heading: styled.div`
    text-transform: uppercase;
    font: 14px;
    padding-left: 45%;
    padding-top: 40px;
    color: #7f7f7f;
  `,
  date: styled.div`
    padding-left: 45%;
    padding-bottom: 40px;
    font: 14px;
    color: #7f7f7f;
  `,
  footer: styled.div`
    background-color: #484a4c;
  `
};

const FooterSection = () => (
  <style.footer>
    <footer>
      <style.heading>
        <h6>Sponsored by</h6>
      </style.heading>
      <ul className="o-section">
        <li><a href="https://www.ipsinvest.com/" target="_blank" rel="noopener noreferrer"><img src={ ipsLogo } alt="IPS invest" /></a></li>
        <li><a href="https://www.wealthmigrate.com/" target="_blank" rel="noopener noreferrer"><img src={ wmLogo } alt="Wealth Migrate" /></a></li>
      </ul>
      <style.date>
        <h6>{ new Date().getFullYear() }, Copyright © Property Going Global</h6>
      </style.date>
    </footer>
  </style.footer>
)

export default FooterSection
